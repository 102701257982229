import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { useUiState } from 'state'
import { ElementId, SlotId, AdBanner } from 'components/ads'
import { TYPE_ASSET, TYPE_PROVIDER } from 'utils/constants'

import styles from './floatingBanner.module.scss'
import Image from 'next/image'
import { Link } from 'components/ui'
import { GTMEvent, logEvent } from 'utils/GTM'
import { useFloatingBanner } from 'contexts/floatingBannerContext'

export const FloatingBanner = () => {
    const router = useRouter()
    const [isVisible, setIsVisible] = useState(true)
    const [data, setData] = useState({})
    const { image } = data
    const isPageBottom = useUiState('isPageBottom')

    const { floatingBannerState } = useFloatingBanner()

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const api =
                    process.env.NEXT_PUBLIC_AD_MANAGER_URL +
                    `/api/b?asset=${floatingBannerState.asset}&tag=${floatingBannerState.ecosystem ? floatingBannerState.ecosystem : floatingBannerState.tag}`
                const response = await fetch(api, {
                    headers: {
                        'X-API-VERSION': 1,
                    },
                })
                const result = await response.json()
                const { name, destination_url, id } = result ?? {}
                const url =
                    process.env.NEXT_PUBLIC_AD_MANAGER_URL +
                    (result.location_url || '')
                const smallImageUrl =
                    process.env.NEXT_PUBLIC_AD_MANAGER_URL +
                    result?.small_location_url
                const image = {
                    imageUrl: url,
                    smallImageUrl,
                    id: id,
                    name: name,
                    destination: destination_url,
                    alt: 'Ad Banner',
                }

                setData({
                    image,
                })
            } catch (error) {
                console.error(error)
            }
        }
        fetchImage()
    }, [floatingBannerState])

    if (
        router.asPath.includes('/terminal') ||
        router.asPath.includes('/summit') ||
        router.asPath.includes('/sreth')
    ) {
        return null
    }

    if (!image?.id) return null
    return (
        <div id='sticky-footer' className={styles.stickyFooter}>
            <div
                className={classNames(styles.floatingBanner, {
                    [styles.hidden]: !isVisible,
                    [styles.bottom]: isPageBottom,
                })}
            >
                <Link
                    className='flex justify-center items-center'
                    href={image.destination}
                    onClick={() => {
                        logEvent(GTMEvent.AdLeaderboardBanner, {
                            ad_action: 'click',
                            ad_id: image.id,
                            ad_name: image.name,
                            click_url: image.destination,
                        })
                    }}
                >
                    <Image
                        className={classNames(
                            'block max-w-[320px] justify-center items-center h-[50px] w-[400px] sm:hidden self-center'
                        )}
                        alt={image.alt}
                        src={image.smallImageUrl}
                        width={0}
                        height={0}
                        sizes='(max-width: 500px) 100vw'
                    />
                    <Image
                        className='hidden sm:block min-w-[728px] justify-center items-center self-center sm:w-[320px] sm:h-[90px] md:w-[728px] md:h-[90px] lg:w-[970px] lg:h-[90px] xl:w-[1140px] xl:h-[90px]'
                        alt={image.alt}
                        src={image.imageUrl}
                        width={0}
                        height={0}
                        sizes='(max-width: 728px) 728px, (max-width: 768px) 728px, (max-width: 1024px) 970px, 1140px'
                    />
                    <div
                        className={styles.closeButtonWrapper}
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsVisible(false)
                        }}
                    >
                        <span
                            className={classNames(
                                `icon icon-close`,
                                styles.closeButton
                            )}
                        />
                    </div>
                </Link>
            </div>
        </div>
    )
}

// Old google ads floating banner, keeping for reference
export const GoogleFloatingBanner = () => {
    const router = useRouter()
    const { pathname, query } = router

    const [isVisible, setIsVisible] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)

    const isPageBottom = useUiState('isPageBottom')

    const slotId = SlotId.FloatingBanner

    const targeting = useMemo(
        () => ({
            component: 'null',
            slug: query?.slug,
            type:
                query?.slug && pathname?.includes('/asset/')
                    ? TYPE_ASSET
                    : TYPE_PROVIDER,
        }),
        [query?.slug, pathname]
    )

    const onLoaded = useMemo(
        () => () => {
            setIsLoaded(true)
        },
        [setIsLoaded]
    )
    if (router.asPath.includes('staking-assistant')) {
        return null
    }

    return (
        <div id='sticky-footer' className={styles.stickyFooter}>
            <div
                className={classNames(styles.floatingBanner, {
                    [styles.hidden]: !isLoaded || !isVisible,
                    [styles.bottom]: isPageBottom,
                })}
            >
                <AdBanner
                    slotId={slotId}
                    id={ElementId.FloatingBanner}
                    targeting={targeting}
                    onLoaded={onLoaded}
                />
                <div
                    className={styles.closeButtonWrapper}
                    onClick={() => {
                        setIsVisible(false)
                    }}
                >
                    <span
                        className={classNames(
                            `icon icon-close`,
                            styles.closeButton
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
